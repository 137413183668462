import { default as indexBlSd6kvQ3dMeta } from "/home/heltia/myagent/_work/7/s/pages/genel-bakis/index.vue?macro=true";
import { default as indexIaSDSTLrIbMeta } from "/home/heltia/myagent/_work/7/s/pages/giris/index.vue?macro=true";
import { default as index7uvRjbbICGMeta } from "/home/heltia/myagent/_work/7/s/pages/index.vue?macro=true";
import { default as indexxgCzh5T5R5Meta } from "/home/heltia/myagent/_work/7/s/pages/noposter/index.vue?macro=true";
import { default as _91code_93GRFh8vR5HiMeta } from "/home/heltia/myagent/_work/7/s/pages/reset-password/[code].vue?macro=true";
import { default as indexGyh5jAgv06Meta } from "/home/heltia/myagent/_work/7/s/pages/uyari/index.vue?macro=true";
export default [
  {
    name: "genel-bakis",
    path: "/genel-bakis",
    meta: indexBlSd6kvQ3dMeta || {},
    component: () => import("/home/heltia/myagent/_work/7/s/pages/genel-bakis/index.vue")
  },
  {
    name: "giris",
    path: "/giris",
    meta: indexIaSDSTLrIbMeta || {},
    component: () => import("/home/heltia/myagent/_work/7/s/pages/giris/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/heltia/myagent/_work/7/s/pages/index.vue")
  },
  {
    name: "noposter",
    path: "/noposter",
    component: () => import("/home/heltia/myagent/_work/7/s/pages/noposter/index.vue")
  },
  {
    name: "reset-password-code",
    path: "/reset-password/:code()",
    component: () => import("/home/heltia/myagent/_work/7/s/pages/reset-password/[code].vue")
  },
  {
    name: "uyari",
    path: "/uyari",
    meta: indexGyh5jAgv06Meta || {},
    component: () => import("/home/heltia/myagent/_work/7/s/pages/uyari/index.vue")
  }
]