import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(FloatingVue, {
    // Global customization
    themes: {
      "info-tooltip": {
        // Base classes applied to all tooltips
        $extend: "tooltip",
        classes: " bg-primaryV2 b2 rounded-2xl p-2 shadow-lg",
      },
    },
  });
});
