<template>
  <section id="error" class="w-full h-screen bg-surface-base">
    <header class="w-full py-5 flex justify-center items-center">
      <NuxtLink to="/">
        <Img
          :src="`${cdn}/heltia-logo.webp`"
          width="80"
          :alt="`${companyName} Logo`"
        />
      </NuxtLink>
    </header>
    <main
      class="flex flex-col items-center justify-center h-[calc(100vh-100px)]"
    >
      <p>{{ getDescriptionTextByError() }}</p>
      <ButtonSecondaryMedium
        @click="handleError"
        class="mt-10"
        label="Anasayfaya dön"
        icon="icon-arrow-left"
      />
    </main>
    <aside
      class="fixed right-0 bottom-0 font-serifV2 text-[20rem] leading-[16rem] text-surface-60 opacity-10"
    >
      {{ error.statusCode }}
    </aside>
  </section>
</template>
<script lang="ts">
import { clearError } from "#app";
import type { NuxtError } from "#app";
import { COMPANY_NAME } from "~/constants";

export default {
  props: {
    error: Object as () => NuxtError,
  },
  data() {
    const config = useRuntimeConfig();
    return {
      companyName: COMPANY_NAME,
      cdn: config.public.cdn,
    };
  },
  methods: {
    handleError() {
      console.log("Clearing errors", this.error);
      clearError({ redirect: "/" });
    },
    getDescriptionTextByError() {
      var httpError = httpErrors.find(
        (httpError) => httpError.statusCode === this.error.statusCode
      );
      if (httpError) {
        return httpError.description;
      }
      return "Bilinmeyen bir hata oluştu.";
    },
  },
};

const httpErrors = [
  {
    statusCode: 400,
    description: "İstek hatalı.",
  },
  {
    statusCode: 401,
    description: "Yetkiniz yok.",
  },
  {
    statusCode: 403,
    description: "Erişim reddedildi.",
  },
  {
    statusCode: 404,
    description: "Aradığınız sayfa bulunamadı.",
  },
  {
    statusCode: 405,
    description: "İstek yöntemi desteklenmiyor.",
  },
  {
    statusCode: 406,
    description: "İstek kabul edilemez.",
  },
  {
    statusCode: 408,
    description: "İstek zaman aşımına uğradı.",
  },
  {
    statusCode: 409,
    description: "Çakışma oluştu.",
  },
  {
    statusCode: 410,
    description: "Kaynak artık mevcut değil.",
  },
  {
    statusCode: 411,
    description: "Uzunluk gerekli.",
  },
  {
    statusCode: 413,
    description: "İstek boyutu çok büyük.",
  },
  {
    statusCode: 414,
    description: "İstek URI çok uzun.",
  },
  {
    statusCode: 415,
    description: "Ortam türü desteklenmiyor.",
  },
  {
    statusCode: 416,
    description: "Aralık isteği uygun değil.",
  },
  {
    statusCode: 417,
    description: "Beklenti başarısız.",
  },
  {
    statusCode: 418,
    description: "Ben bir çaydanlığım.",
  },
  {
    statusCode: 422,
    description: "İşlem başarısız.",
  },
  {
    statusCode: 429,
    description: "Çok fazla istek.",
  },
  {
    statusCode: 500,
    description: "Sunucu hatası oluştu.",
  },
  {
    statusCode: 501,
    description: "İmplementasyon eksik.",
  },
  {
    statusCode: 502,
    description: "Geçersiz ağ geçidi.",
  },
  {
    statusCode: 503,
    description: "Servis kullanılamaz.",
  },
  {
    statusCode: 504,
    description: "Ağ zaman aşımına uğradı.",
  },
  {
    statusCode: 505,
    description: "HTTP sürümü desteklenmiyor.",
  },
  {
    statusCode: 506,
    description: "Varyant ayrıştırması gerekiyor.",
  },
  {
    statusCode: 507,
    description: "Depolama alanı sınırı aşıldı.",
  },
  {
    statusCode: 508,
    description: "Döngü algılandı.",
  },
  {
    statusCode: 510,
    description: "Uygulanmamış uzatma.",
  },
  {
    statusCode: 511,
    description: "Ağ kimlik doğrulaması gerekli.",
  },
  {
    statusCode: 520,
    description: "Bilinmeyen hata.",
  },
  {
    statusCode: 521,
    description: "Web sunucusu erişilemez.",
  },
  {
    statusCode: 522,
    description: "Bağlantı zaman aşımı.",
  },
  {
    statusCode: 523,
    description: "Köken erişilemez.",
  },
  {
    statusCode: 524,
    description: "Zaman aşımı.",
  },
  {
    statusCode: 525,
    description: "SSL el sıkışması başarısız.",
  },
  {
    statusCode: 526,
    description: "SSL sertifikası hatalı.",
  },
  {
    statusCode: 527,
    description: "Railgun hata.",
  },
  {
    statusCode: 530,
    description: "Köken sertifikası hatalı.",
  },
  {
    statusCode: 598,
    description: "Ağ okuma zaman aşımı.",
  },
  {
    statusCode: 599,
    description: "Ağ yazma zaman aşımı.",
  },
];
</script>
