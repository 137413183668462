import mixpanelBrowser from "mixpanel-browser";

var mixpanel = {
  _base: {
    token: null,
    m: null,
  },
  init(key) {
    this._base.m = mixpanelBrowser;
    this._base.token = key;

    this._base.m.init(this._base.token);

    const referrerUrl = document.referrer;
    if (referrerUrl) {
      this._base.m.people.set("AcquisitionWebUrl", referrerUrl);
      this._base.m.register({
        AcquisitionWebUrl: referrerUrl,
      });
    }

    return this;
  },
  setUser(user) {
    this._base.m.identify(user.id);
    this._base.m.alias(user.id, user.id);
    this._base.m.people.set("Name", user.firstname);
    this._base.m.people.set("Surname", user.lastname);
    this._base.m.people.set("Email", user.email);
    this._base.m.people.set("SponsorId", user.sponsors[0].identifier);
    this._base.m.register({
      Name: user.firstname,
      Surname: user.lastname,
      Email: user.email,
      SponsorId: user.sponsors[0].identifier,
    });

    return this;
  },
  clearUser() {
    this._base.m.reset();

    return this;
  },
  track(eventName, obj) {
    this._base.m.track(eventName, obj);

    return this;
  },
  timeEvent(eventName) {
    this._base.m.time_event(eventName);

    return this;
  },
};

export default mixpanel;
