import type { Token } from "~/model/common";
import { isAfter } from "date-fns";
import { axiosInstance } from "~/plugins/axios";

export const isTokenExpired = (token: Token): boolean => {
  if (!token) {
    return false;
  }

  const now = new Date();
  const expiryDate = new Date(token.expires);
  return isAfter(now.toISOString(), expiryDate);
};

export const setSession = (
  token: Token,
  refreshToken: Token,
  updateLocalStorage: boolean
) => {
  if (token && refreshToken && axiosInstance) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token.token}`;

    if (updateLocalStorage) {
      LocalStorageUtil.setItem(LocalStorageKeys.Token, token);
      LocalStorageUtil.setItem(LocalStorageKeys.RefreshToken, refreshToken);
    }
  }
};

export const clearSession = () => {
  LocalStorageUtil.removeItem(LocalStorageKeys.Token);
  LocalStorageUtil.removeItem(LocalStorageKeys.RefreshToken);
  delete axiosInstance.defaults.headers.common.Authorization;
};
