<template>
  <NuxtImg
    format="webp"
    quality="80"
    :loading="$props.loading ?? 'lazy'"
    :sizes="$props.sizes ?? 'xl:50vw lg:100vw md:80vw sm:100vw xs:100vw'"
    :src="$props.src"
    :alt="$props.alt"
    :class="$props.class"
  />
</template>

<script>
export default {
  name: "SalusImage",
};
</script>
