import pojo_payload_XFWf8Pcl4q from "/home/heltia/myagent/_work/7/s/plugins/pojo-payload.ts";
import revive_payload_client_4sVQNw7RlN from "/home/heltia/myagent/_work/7/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/heltia/myagent/_work/7/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/heltia/myagent/_work/7/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/heltia/myagent/_work/7/s/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/heltia/myagent/_work/7/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/heltia/myagent/_work/7/s/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/heltia/myagent/_work/7/s/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/heltia/myagent/_work/7/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/heltia/myagent/_work/7/s/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/heltia/myagent/_work/7/s/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/heltia/myagent/_work/7/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_YksOOA8Nkt from "/home/heltia/myagent/_work/7/s/node_modules/nuxt-snackbar/dist/runtime/plugin.js";
import plugin_client_4F1kZh3YAB from "/home/heltia/myagent/_work/7/s/node_modules/nuxt-viewport/dist/runtime/plugin.client.js";
import ua_parser_53BANzKwiu from "/home/heltia/myagent/_work/7/s/plugins/ua-parser.ts";
import mixpanel_plugin_6j8BkO1a4Y from "/home/heltia/myagent/_work/7/s/plugins/mixpanel-plugin.ts";
import vue_virtual_scroller_O7lMY10m40 from "/home/heltia/myagent/_work/7/s/plugins/vue-virtual-scroller.ts";
import highcharts_client_MYKkaxDq22 from "/home/heltia/myagent/_work/7/s/plugins/highcharts.client.ts";
import axios_QMFgzss1s4 from "/home/heltia/myagent/_work/7/s/plugins/axios.ts";
import device_PxhEqcDcOg from "/home/heltia/myagent/_work/7/s/plugins/device.ts";
import floating_vue_BiOD74u9sH from "/home/heltia/myagent/_work/7/s/plugins/floating-vue.ts";
import markdownit_fsFV40uZ9C from "/home/heltia/myagent/_work/7/s/plugins/markdownit.ts";
import maska_UHaKf2z1iQ from "/home/heltia/myagent/_work/7/s/plugins/maska.ts";
export default [
  pojo_payload_XFWf8Pcl4q,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_YksOOA8Nkt,
  plugin_client_4F1kZh3YAB,
  ua_parser_53BANzKwiu,
  mixpanel_plugin_6j8BkO1a4Y,
  vue_virtual_scroller_O7lMY10m40,
  highcharts_client_MYKkaxDq22,
  axios_QMFgzss1s4,
  device_PxhEqcDcOg,
  floating_vue_BiOD74u9sH,
  markdownit_fsFV40uZ9C,
  maska_UHaKf2z1iQ
]