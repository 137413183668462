class GoogleAnalytics {
    static trackEvent(event: string, params: any) {
        if (typeof window !== 'undefined' && (window as any).gtag) {
            if (params !== null) {
                (window as any).gtag('event', event, params);
            } else {
                (window as any).gtag('event', event);
            }
        }
    }
}

export default GoogleAnalytics;