export type Route = {
  name: string;
  path: string;
  visibleOnMobile: boolean;
};

export type RouteKey = "home" | "signin" | "overview" | "resources" | "warning";

export const routes: Record<RouteKey, Route> = {
  home: {
    name: "anasayfa",
    path: "/",
    visibleOnMobile: false,
  },
  signin: {
    name: "giris",
    path: "/giris",
    visibleOnMobile: false,
  },
  overview: {
    name: "genel bakis",
    path: "/genel-bakis",
    visibleOnMobile: false,
  },
  resources: {
    name: "kaynaklar",
    path: "/kaynaklar",
    visibleOnMobile: false,
  },
  warning: {
    name: "uyari",
    path: "/uyari",
    visibleOnMobile: true,
  },
};
